import {
  ComponentClientSpecMapEntry,
  ComponentRef,
} from '@wix/editor-platform-sdk-types';

import { migrate } from 'settings/migration';

import { GROUPS_APP_DEFINITION_ID } from '../../../constants';
import { EditorPlatformMigration } from '../EditorPlatformMigration';
import {
  toColorStyleParams,
  toFontStyleParams,
  toNumericStyleParams,
} from './helpers';

/**
 * Migrates legacy settings
 */
export default class extends EditorPlatformMigration {
  name = 'styleParams-01';

  async migrate(component: ComponentClientSpecMapEntry, ref: ComponentRef) {
    const [params, publicData] = await Promise.all([
      this.sdk.document.tpa.getStyleParams(GROUPS_APP_DEFINITION_ID, {
        compRef: ref,
      }),
      this.sdk.document.tpa.data.getAll(GROUPS_APP_DEFINITION_ID, {
        compRef: ref,
      }),
    ]);

    const result = migrate(params, publicData);

    const fonts = toFontStyleParams(result.fonts);
    const colors = toColorStyleParams(result.colors);
    const numbers = toNumericStyleParams(result.numbers);

    const styleParams = [fonts, colors, numbers].flat();

    if (styleParams.length === 0) {
      return true;
    }

    await this.sdk.document.tpa.setStyleParams(GROUPS_APP_DEFINITION_ID, {
      styleParams,
      compRef: ref,
    });

    return true;
  }
}
